:root {
    --dark-blue: rgb(3,15,20);
    --blue: #005776;
    --light-blue: #BBDDE6;
    --red: #CC3333;
    --green: #279989;
    --stone: #E1D3C4;
    --light-stone: rgb(247,241,237);
    --soft-black: #171919;
    --sexy-blue: rgb(132, 167, 180);
  }

  ul {
    padding: 0;
  }

  @font-face {
    font-family: 'BrownProTT-Bold';
    src: url('../../assets/fonts/BrownProTT-Bold.ttf') format('truetype');
  }
  


.description-flex {
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.description-left-column {
  width: 600px;
}

.description-right-column {
    width: 400px;
    /* background-color: var(--green); */
    color: white;
    /* background-color: var(--sexy-blue); */
    padding: 0px 20px 0px 20px;
    border-radius: 15px;
    /* box-shadow: 5px 5px 30px .1px rgba(50, 100, 55, 0.2); */
}

.description-right-column p {
  /* color: white; */
}

.description-right-column:hover {
  box-shadow: 5px 5px ¨30px .1px rgba(50, 100, 55, 0.3);
  /* color: white; */
}


.subtitle {
    font-weight: 200;
    font-size: 39px;
    
    padding: 10px;
    color: var(--stone);
    color: var(--green);
    box-shadow: 0px 0px 10px 5px linear-gradient(to right, var(--red), var(--green), var(--blue), var(--light-blue)) 1;
    color: rgba(0,0,0, .6);
    color: orange;
    color: white;
    background-color: rgb(132, 167, 180);
    text-decoration: underline solid 10px;

    
  }
  
  #kuk:hover {
    box-shadow: 0px 0px 10px 5px green;

  }

  .li-blobs {
    /* background-color: var(--blue); */
    /* color: white; */
    /* font-size: 22px; */
    border-radius: 15px;
    display:inline-block;
    padding: 20px;
    margin: 10px;
    list-style: none;
    /* background-color: var(--light-stone); */
    color: black;
    font-weight: 100;
    font-size: 28px;
    border: 1px solid white;
    color: white;
}


  @media (max-width: 800px) {
    .description-flex {
        flex-direction: column;
    }
    .description-right-column {
        width: 100%;
        padding: 0;
    }
    .subtitle {
        font-size: 38px;
        margin: 0;
    }
    h2 {
      padding: 0;
    }
  }
  