@-ms-viewport {
  width: device-width;
}

:root {
  --dark-blue: rgb(25, 35, 45);
  --blue: #005776;
  --light-blue: #BBDDE6;
  --red: #CC3333;
  --green: #279989;
  --stone: #E1D3C4;
  --sand: rgb(230, 222, 218);
  --light-stone: rgb(247, 241, 237);
  --soft-black: #171919;
  --gradient: linear-gradient(to right, var(--red), var(--green), var(--blue), var(--light-blue)) 1;
  --sexy-blue: rgb(132, 167, 180);
}

ul {
  margin: 20px;
  margin-left: 40px;
}

li {
  margin: 10px 0px 10px 0px;
}

@font-face {
  font-family: 'BrownProTT-Bold';
  src: url('./fonts/BrownProTT-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'NeoTwoZero';
  src: url('./fonts/NeoTwoZero-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
}
@font-face {
  font-family: 'NeueHaasDisplayBlack';
  src: url('./fonts/NeueHaasDisplayBlack.ttf') format('truetype');
}

@font-face {
  font-family: 'NeueHaasDisplayBold';
  src: url('./fonts/NeueHaasDisplayBold.ttf') format('truetype');
}

@font-face {
  font-family: 'NeueHaasDisplayRoman';
  src: url('./fonts/NeueHaasDisplayRoman.ttf') format('truetype');
}

@font-face {
  font-family: 'NeueHaasDisplayLight';
  src: url('./fonts/NeueHaasDisplayLight.ttf') format('truetype');
}

@font-face {
  font-family: 'NeueHaasDisplayXThin';
  src: url('./fonts/NeueHaasDisplayXThin.ttf') format('truetype');
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--soft-black);
  background-color: linear-gradient(to right, var(--red), var(--green), var(--blue), var(--light-blue));
  background-color: rgb(230, 222, 218);
  background-color: white;
  background-color: var(--light-stone);
}

body,
html {
  overflow-x: hidden;
}


body::selection {
  /* color: pink; */
  background-color: var(--green);
  color: white;
}

h1::selection {
  /* background-color: var(-green); */
  /* color: var(--green); */
  }
  
  p::selection {
    background-color: var(--stone);
    color: var(--dark-blue);
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-container {
  padding: 0px;
  background-color: red;
}

.hero-container {
  margin-top: 100px;
}

a {
  text-decoration: none;
}

.mobile-li {
  display: inline;
  font-size: 60px;
  color: var(--soft-black);
  /* font-family: BrownProTT-Bold; */
}

.mobile-li:active .mobile-li.focus {
  color: blue
}



.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px 20px 25px;
  position: fixed;
  /* background-color: rgba(255, 255, 255, .4); */
  top: 0;
  left: 0;
  /* background-color: white; */
  z-index: 1;
  right: 0;
  /* border-bottom: 1px solid grey; */
  /* box-shadow: 1px 1px 1px rgba(200, 200, 200, 1); */
  /* background-color: green; */
  /* bottom: 0; */
}

.nav-desktop-menu-item {
  display: inline;
  font-size: 20 px;
  /* color: black; */
    text-decoration: none;

}

.nav-desktop-menu-item:hover {
  /* color: var(--green);
      background: linear-gradient(to right, var(--red), var(--green), var(--blue), var(--light-blue)); 
     -webkit-text-fill-color: transparent; 
    -webkit-background-clip: text;
    background-clip: text; */
  /* color: var(--green) */
  color: white;
    text-decoration: none;
}

a.nav-item-night {
  color: white !important;
}

a.nav-item-sand {
  color: var(--stone) !important;
  /* background-color: white; */
}

.buildx-color-border {
  padding: 2px;
  display: inline-block;
  border-bottom: 10px solid;
  border-image: linear-gradient(to right, var(--red), var(--green), var(--blue), var(--light-blue)) 1;
}

.service-item {
  /* text-decoration: underline solid 10px var(--blue); */
  color: black;
}

a {
  /* color: var(--sexy-blue); */
    /* color: white; */
}

h1 {
  font-size: 60px;
  /* font-weight: 800; */
  color: var(--soft-black);
  /* font-family: BrownProTT-Bold; */
    font-family: NeueHaasDisplayBold;

}

h2 {
  font-size: 50px;
  font-weight: 100;
  color: rgb(40, 40, 40);
  line-height: 1.8;
  display: inline;
  opacity: 1;
  transition: opacity 1s;
  padding: 10px;
  /* background-color: rgba(10, 20, 30, 0.5); */
  color: white;
  transition: background-color .3s;
  transition: color .1s;
  /* background-color:  rgba(10, 20, 30, .4) */
  color: var(--light-stone);
  color: var(--green);
  color: black;
  padding-left: 0;
  /* background-color: var(--green); */
  font-family: NeueHaasDisplayXThin;
}

h3 {
  font-size: 26px;
    font-weight: 200;
    margin-bottom: 0;
      padding-bottom: 0;
      /* font-family: Arial; */
      /* color: var(--blue); */
      /* font-family: NeueHaasDisplayBold; */
      /* font-family: BrownProTT-Bold; */
}
p::selection,
h2::selection {
  /* color: pink; */
  /* color: var(--green); */
  /* color: white; */
}

p {
  font-size: 25px;
  /* color: white;
  color: var(--stone);
  color: rgb(215, 140, 1);
  color: var(--green);
  color: black;
    */
  padding: 0;
  font-family: NeueHaasDisplayRoman;

  /* color: orange; */
}

.nav-item-basic {
  color: black;
  /* font-size: 40px; */
}

.nav-item-highlight {
  text-decoration: underline solid black;
}



.animate-active {
  /* transition: background-color .3s;
  transition: color .1s;
  background: linear-gradient(to right, var(--red), var(--green), var(--blue), var(--light-blue)); 
 -webkit-text-fill-color: transparent; 
-webkit-background-clip: text;
background-clip: text;
color: pink; */
  color: var(--green);
}

@keyframes fadeInColor {
  0% {
    color: rgba(0, 0, 0, 1);
  }

  100% {
    color: var(--light-stone);
  }
}
@keyframes fadeInBgColor {
  0% {
    background-color: rgba(228, 231, 255, 0.1);
  }

  60% {
    background-color: rgba(228, 231, 255, 0.6);
  }
}

/* THE REAL HIGHLIGHT  */

.hero-highlight {
  text-decoration: none;
  position: relative;
  display: inline-block;
  /* color: white; */
  color: black;
  z-index: 0;
}

.hero-highlight:hover {
  background-color: var(--sexy-blue);
  transition: background-color .2s;
  text-decoration: none;
  animation: fadeInColor .2s ease-in-out forwards;
}
.hero-highlight::after {
  content: '';
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 10px;
  background-color: var(--sexy-blue);
  color: white;
    transform: translateX(-50%);
    animation: expand-underline 0.6s ease-in-out forwards;
    text-decoration: none;
  }
  
  .hero-highlight-green {
    text-decoration: none;
    position: relative;
    display: inline-block;
    /* color: white; */
    color: black;
    z-index: 0;
  }
  
  .hero-highlight-green:hover {
    background-color: var(--green);
    color: var(--light-stone);
    transition: background-color .2s;
    text-decoration: none;
    animation: fadeInColor .2s ease-in-out forwards;
  }
  
  .hero-highlight-green::after {
    content: '';
    z-index: -1;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 10px;
    background-color: var(--green);
    /* color: white; */
  transform: translateX(-50%);
  animation: expand-underline 0.6s ease-in-out forwards;
  text-decoration: none;
}

@keyframes expand-underline {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}


a:hover {
  text-decoration: underline solid white;
}

.hero-service-resting::hover {
  color: black;
  background: linear-gradient(to right, var(--red), var(--green), var(--blue), var(--light-blue));
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;

}





@keyframes call-to-action-scroll {
  0% {
    transform: translateX(-40%);
  }

  100% {
    transform: translateX(100%);
  }
}


.scroll-down-animation {
  position: absolute;
  bottom: 0;
  padding-bottom: 40px;
  left: 50%;
  transform: translateX(-10%);
  opacity: 0;
  transition: opacity .3s;
  cursor: pointer;
}

.scroll-down-animation.visible {
  opacity: 1;
}

.scroll-down-animation svg {
  width: 24px;
  height: 24px;
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  animation: scrollDown 1s infinite;
}

@keyframes scrollDown {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(4px);
  }

  100% {
    transform: translateY(0);
  }
}


/* PAGE TRANSITION */

/* .fade-enter {
  opacity: 0;
  position: absolute;
  width: 100%;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.fade-exit {
  opacity: 1;
  position: absolute;
  width: 100%;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-in;
}

.transition-group-container {
  position: relative;
} */

.contact-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cobe-image {
  background-image: url('./img/park-modern.jpeg');
  /* Replace with the path to your image */
  background-size: cover;
  /* background-repeat: no-repeat; */
  width: 100%;
  height: 600px;
  /* Set a specific height for the background div */
  color: white;
}
.home-service-card {
  padding: 20px;
  flex: 1;
  width: 200px;
  /* width: 20%; */
  /* width: 220px; */
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(26, 35, 44, .4);
    color: var(--light-stone);
}

.home-service-card:hover {
  background-color: rgba(26, 35, 44, .3);

    /* background-color: var(--dark-blue); */
    text-decoration: none;
  color: white;
    /* animation: fadeInBgColor .3s ease-in-out; */
}
.header {
  display: inline;
  padding: 5px;
  font-family: NeueHaasDisplayBold;
  font-size: 25px;
  margin-bottom: 0px;
  color: white;
  background-color: var(--green);
  /* animation: expand-underline 20s ease-in-out forwards; */
}
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/arrow-right.css');
area {
  shape-rendering: crispEdges;
}
/* src/App.css */
.page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.page.active {
  opacity: 1;
}
@media (max-width: 800px) {
  h2 {
    font-size: 32px;
    line-height: 2.2;
    margin-top: 0
  }

  p {
    font-size: 20px;
  }

  .navbar {
    left: 0;
    z-index: 1;
    right: 0;
    top: 0;
  }

  .contact-container {
    flex-direction: column;
  }

  .hero-highlight::after {
    padding-bottom: 1px;
  }

  .mobile-li {
    font-size: 39px;
  }

  .home-service-card {
    /* min-width: calc(33.33% - 2px); */
      /* width: 100%; */
      /* flex: 1 1 33%; */
      padding: 40px;
      box-sizing: border-box;
      flex: 1;
      width: 33vw;
      /* max-width: 33vw; */
    }
    }
    
    @media (min-width: 2000px) {
      p {
        font-size: 40px;
      }
    
      .nav-desktop-menu-item {
        display: inline;
        font-size: 30px;
        /* color: black; */
        text-decoration: none;
      }
}